import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout/layout"
import HeroSimple from "../components/hero/heroSimple/heroSimple"
import PageBuilder from "../components/pageBuilder/pageBuilder"
import Seo from "../components/seo/seo"

const LP = ({
  data: {
    sanityLandingPage: {
      tabs: { content: page, seo },
    },
  },
}) => {
  return (
    <Layout hideAllMenus={page?.hideAllMenus}>
      <Seo {...seo} />
      <HeroSimple blocks={page._rawHero[0].children} small={true} />
      <PageBuilder pageBuilder={page.pageBuilder} />
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    sanityLandingPage(tabs: { content: { slug: { current: { eq: $slug } } } }) {
      tabs {
        content {
          title
          _rawHero
          pageBuilder {
            ...PageBuilder
          }
          hideAllMenus
        }
        seo {
          canonical
          description
          metaTitle
          noIndex
          openGraphImage {
            asset {
              url
            }
          }
          removeSitemap
        }
      }
    }
  }
`

export default LP
